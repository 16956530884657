/* Colour Variables */

$primaryColor: #787570;
$secondaryColor: #CFCFD1;
$thirdColor: #000050;
$lightColor: #FFFFFF;
$darkColor: #991B1D;

/* Basic Styles */
*, body { box-sizing: border-box; margin: 0; padding: 0; font-family: "Kodchasan", sans-serif; }
body, html { 
	font-size: 18px; width: 100%; height: 100%; margin: auto; background-color: $lightColor;
		color: $thirdColor;
}
a { text-decoration: underline; color: $darkColor; }
div[id*="page"] {
	margin: 0 10px;

	h2 { margin-top: 0; }

	.c-wrapper {
		padding: 10px;

		.button i { margin-right: 10px; }
	}
}
h1, h2, h3, h4, h5 { color: $darkColor; margin-bottom: 20px; }
h1 { font-size: 32px; margin-top: 10px; }
h2 { font-size: 28px; }
hr { border: 4px solid $primaryColor; margin-bottom: 10px; }
iframe { border: none; }
img { max-width: 100%; width: 100%; }
input, textarea, select {
	border: 2px solid $primaryColor; padding: 10px; color: $secondaryColor;

	&:focus { outline: none; border: 2px solid $secondaryColor; }
	&:disabled {
		background-color: lighten($primaryColor, 50); border-color: lighten($primaryColor, 50);
			text-align: center; -webkit-appearance:none; -moz-appearance: none;
			font-family: "Kodchasan", sans-serif; font-size: 18px; opacity: 1;

		&:-ms-expand { display: none; }
	}
}
form {
	border: 2px solid $primaryColor; padding: 10px; margin-bottom: 10px;

	a { width: 90%; display: block; text-align: center; }
	button {
		background-color: $primaryColor; color: $lightColor; border: 2px solid $primaryColor;
			width: 100%; padding: 10px; font-size: 14pt;
	}
	input, textarea, label {
		margin-bottom: 10px; display: block; width: 100%;
	}
	input, textarea {
		border: 2px solid $primaryColor; padding: 10px; color: $secondaryColor;

		&:focus { outline: none; border: 2px solid $secondaryColor; }
	}
	input[type="submit"] { width: 100%; margin: 0; }
	textarea { resize: vertical; }
	& > p { width: 50%; display: block; margin: 0 auto; text-align: center; }
}
p { margin: 10px 0px; }
ul { margin-left: 20px; }

#header {
	#contact {
		text-align: center;

		a { margin: 10px 5px; }
		p { margin: 0; }
		span { display: block; }
	}
	#navigation { 
			background-color: $primaryColor;

		ul { list-style-type: none; margin: 0; padding: 0; }
		li {
			&.active > a { background-color: $secondaryColor; color: $thirdColor; }

			a {
				display: block; font-weight: 700;
					font-size: 24px; text-decoration: none; width: 100%;
					padding: 15px; color: $lightColor;
			}

			&.parent-cat {
				ul {
					margin: 10px 0; margin-left: 20px;
				}
			}
			
			ul {
				background-color: darken($secondaryColor, 0.2); margin: 10px 0; margin-left: 20px; color: $darkColor
			}
		}
		#nav { display: none; }
		#menu { 
			display: block; text-align: center; background-color: $primaryColor;
				cursor: pointer; padding: 10px 0; font-size: 24px; -webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out;
				color: $lightColor;
			&.open { background-color: $darkColor; color: $lightColor; }
		}
	}
	#logo { display: inline-block; }
}
.button, input[type="submit"], input[type="button"] {
	display: inline-block; background-color: $primaryColor; border: 2px solid $primaryColor; margin: 0 5px;
		text-align: center;	text-decoration: none; color: $lightColor; padding: 10px; vertical-align: middle;
		font-size: 18px;
}
.card {
	border: 2px solid $primaryColor; height: 100%; padding: 20px; align-items: center;

	a { font-size: 11.5pt; }
}
.col-wrapper {
	margin-bottom: 10px;

	&.single { justify-content: center; }
}
.center { text-align: center; }
.go { display: block; background-color: $primaryColor; padding: 10px; color: $lightColor; text-decoration: none; }
.left { text-align: left; }
.right { text-align: right; }
.round { border-radius: 40px; }
.shout { color: $darkColor; font-weight: 700; }
.tablet, .tabland, .mland, .desktop { display: none; }
.three-block {
	margin-top: 20px;

	.button { margin: 0; }
	.image img { width: 100%; }
}
.mobile { display: block; } // Keep this here! Overrides above rules.
.wrapper { background-color: #fff; }

#footer {
	background-color: $primaryColor; color: $lightColor; 
		padding: 10px; margin-bottom: 0;

	h2 { margin-top: 0px; }
	h2, h3 { color: $lightColor; }
	a { color: $lightColor; word-wrap: break-word; }
	iframe { width: 100%; border: none; height: 100%; }
	div {
		margin-bottom: 20px;

		&:last-child { margin-bottom: 0; }
	}
}
#copyright {
	background-color: $secondaryColor; padding: 0 10px; font-size: 14px;
		color: $lightColor; padding-top: 10px;

		a { color: $lightColor; text-decoration: underline; }
		p { margin: 0; padding-bottom: 10px; }
}

/* Flexslider Settings */
	.flexslider {
		margin-bottom: 40px; border: 0;
		
		#slide1 { background: url("../images/banners/banner-01-small.jpg") no-repeat center; }
		#slide2 { background: url("../images/banners/banner-02-small.jpg") no-repeat center; }
		#slide3 { background: url("../images/banners/banner-03-small.jpg") no-repeat center; }
		.flex-direction-nav {
			a { 
				height: 53px; background-color: rgba($primaryColor, 0.85);
					color: $lightColor;
			}

			.flex-next { right: 0; text-align: center; }
			.flex-prev { left: 0; text-align: center; }
		}
		.flex-control-nav {
			a {
				background-color: $primaryColor; vertical-align: middle;
					border: 2px solid $lightColor; height: 20px; width: 20px;
			}
			.flex-active {
				background-color: $secondaryColor; border-color: $secondaryColor;
			}
		}

		.slides li {
			height: 300px; position: relative;

			div {
				position: absolute; bottom: 0; background-color: rgba($primaryColor, 0.85); color: $secondaryColor; padding: 10px;
					width: 100%;

				h2 { color: $lightColor; }
				p { display: none; }
			}
		}
	}
	.loading {
		#container { opacity: 0; }
		&:before  { content: 'LOADING'; display: block; margin: 100px 0 0; text-align: center; color: #fff; font-weight: bold; font-size: 60px; opacity: 0.3; }
	}

@media only screen and (min-width: 460px) {
	.mobile { display: none; }
	.mland { display: block; }

	#logo { width: 60%; }
}

@media only screen and (min-width: 768px) {
	#header .col-6 { width: 100%; }
	#logo { width: 30%; }

	/* Flexslider Styles */
	.flexslider {
		#slide1 { background: url("../images/banners/banner-01-medium.jpg") no-repeat center; }
		#slide2 { background: url("../images/banners/banner-02-medium.jpg") no-repeat center; }
		#slide3 { background: url("../images/banners/banner-03-medium.jpg") no-repeat center; }

		.slides li {
			height: 335px;

			div {
				h2 { margin-bottom: 10px; }
				p { display: block; color: $secondaryColor; }
			}
		}
	}

	.mland { display: none; }
	.tablet { display: block; }
	.col-wrapper {
		display: flex; flex-wrap: wrap; justify-content: space-between;

		.col-1, .col-2, .col-3, .col-4, .col-5, .col-7, .col-8, .col-9, .col-10, .col-11 { width: 100%; }
		.col-6 { width: calc((100% / 2) - 1%); }
	}
	.wrapper { margin: 0 auto; }
}

@media only screen and (min-width: 960px) and (orientation: landscape) {
	#header.col-wrapper, .col-wrapper {
		display: flex; justify-content: space-between;

		.col-1 { width: calc((100% / 12) - 1%); }
		.col-2 { width: calc((100% / 6) - 1%); }
		.col-3 { width: calc((100% / 4) - 1%); }
		.col-4 { width: calc((100% / 3) - 1%); }
		.col-5 { width: calc(((100% / 12) * 5) - 1%); }
		.col-6 { width: calc((100% / 2) - 1%); }
		.col-7 { width: calc(((100% / 12) * 7) - 1%); }
		.col-8 { width: calc(((100% / 3) * 2) - 1%); }
		.col-9 { width: calc(((100% / 4) * 3) - 1%); }
		.col-10 { width: calc(((100% / 6) * 5) - 1%); }
		.col-11 { width: calc(((100% / 12) * 11) - 1%); }
	}
	#header {
		#contact {
			text-align: right; height: 100%;

			a {
				display: inline-block; width: auto; vertical-align: middle;
			}
			p { height: 100%; margin-right: 10px; }
			span { margin-top: 20px; }
		}
		#logo { width: 100%; }
		#navigation {
			li {
				display: inline-block;

				a { font-size: 18px; padding: 20px 30px; }

				&.parent-cat ul { display: none; }
			}

			#menu { display: none; }
			#nav { display: block; border: none; }
		}
	}

	.three-block {
		display: flex; justify-content: space-between; flex-wrap: wrap;

		& > div {
			width: calc((100% / 3) - 1%); font-size: 16px;

			&.text.one { order: 0; }
			&.image.two { order: 1; }
			&.text.three { order: 2; }
			&.image.one { order: 3; }
			&.text.two { order: 4; }
			&.image.three { order: 5; }

			h2 { margin-top: 0; margin-bottom: 5px; }
		}
	}
}

@media only screen and (min-width: 1200px) {
	a:hover { color: $thirdColor; }

	#header #navigation li {
		&:hover {
			background-color: $secondaryColor;

			> a { color: $thirdColor; }
		}

		&.parent-cat:hover {
			> .nav-child {
				display: block; position: absolute;
					background-color: $primaryColor; z-index: 10; width: 260px; margin: 0;

				li { 
					width: 100%;

					a {	width: 100%; margin: 0;	}
				}
			}
		}
	}

	.button, input[type="submit"], input[type="button"] {
		&:hover { background-color: $secondaryColor; color: $thirdColor; border-color: $secondaryColor; }
	}
	.flexslider .flex-control-nav a:hover { background-color: $lightColor; border-color: $primaryColor; }

	/* Flexslider Settings */
		.flexslider {
			#slide1 { background: url("../images/banners/banner-01-large.jpg") no-repeat center; }
			#slide2 { background: url("../images/banners/banner-02-large.jpg") no-repeat center; }
			#slide3 { background: url("../images/banners/banner-03-large.jpg") no-repeat center; }
		}
		
		.slides li { height: 400px; }
		.flex-control-paging li a:hover { background-color: $lightColor; border: 2px solid $darkColor; }
		.flex-control-thumbs img:hover { opacity: 1; }
		.flexslider:hover .flex-direction-nav .flex-prev { opacity: 0.7; }
	  	.flexslider:hover .flex-direction-nav .flex-prev:hover { opacity: 1; }
	  	.flexslider:hover .flex-direction-nav .flex-next { opacity: 0.7; }
	  	.flexslider:hover .flex-direction-nav .flex-next:hover { opacity: 1; }

	.wrapper { width: 1200px; }
}